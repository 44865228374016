<template>
  <v-container fluid fill-height>
    <v-layout justify-center column>
      <v-form v-model="valid" @submit.prevent="onRegister">
        <v-container>
          <v-row>
            <v-col>
              <h2
                style=""
                class="white--text mb-6 mt-4 display-1 text-xs-center font-weight-bold"
              >
                SKAPA ETT KONTO
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                rounded
                label="Förnamn*"
                required
                v-model="firstname"
                filled
                background-color="grey lighten-2"
                color="black"
              ></v-text-field>
              <v-text-field
                label="Email*"
                required
                v-model="email"
                :rules="emailRules"
                :type="username"
                rounded
                background-color="grey lighten-2"
                filled
                color="black"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                rounded
                v-model="last"
                label="Efternamn"
                filled
                background-color="grey lighten-2"
                color="black"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Lösenord*"
                filled
                v-model="password"
                rounded
                background-color="grey lighten-2"
                color="black"
                :type="'password'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Lösenord*"
                filled
                v-model="passwordRepeated"
                rounded
                background-color="grey lighten-2"
                color="black"
                :type="'password'"
              ></v-text-field>
            </v-col>
          </v-row>
       
           <v-row align="center"
      justify="center">
            <v-col cols="12"
            class="mt-0 pt-0"
            sm="5"
            md="5">
              <div>
                <v-checkbox
                  color="orange"
                  class="  "
                  v-model="checkboxGDRP"
                  :rules="[
                    (v) =>
                      !!v ||
                      'Du måste godkänna Integritetspolicy för att fortsätta',
                  ]"
                  required
                >
                  <div slot="label" style="color: white; font-size: 1.1em">
                    Jag har läst och accepterar
                    
                      <a @click="openPolicy"> Integritetspolicy* </a>
                    
                  </div>
                   
                </v-checkbox>
              </div>
              <v-btn filled rounded color="third" large type="submit"
                >Registrera mig</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-layout>
    <v-dialog v-model="gdprDialog" width="500" class="">
      <div class="white text-md-left" style="padding: 50px 30px">
        <div class="summary-content">
          <h1></h1>
          <h1>Privacy Policy</h1>

          <p style="outline: none">
            Samtycke till behandling<br />
            Vilka personuppgifter samlar ni in när jag besöker www.ulrika.se?<br />
            Teknisk information<br />
            Vilka personuppgifter samlar ni på www.ulrika.se?<br />
            Hur använder ni mina personuppgifter?<br />
            Dina rättigheter<br />

            senaste ändrad 26 januari 2021
          </p>
        </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  components: {},
  data: () => ({
    username: "",
    gdprDialog: false,
    checkboxGDRP: false,
    valid: false,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    email: "",
    firstname: "",
    lastname: "",
    kommun: "",
    city: "",
    phone: "",
    password: "",
    company: "",
    contactPerson: "",
    radioGroup: null,
    passwordRepeated: '',
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail måste vara gilltig",
    ],
    items: ["Kinda", "Motala", "Söderköping"],
  }),
  methods: {
    openPolicy(){
window.open("https://www.ulricakollberg.se/wp-content/uploads/2021/06/Integritetspolicy.pdf", '_blank');
    },
    async onRegister() {
      if(this.password != this.passwordRepeated)
      {
          alert("Lösenorden matchar inte varandra.");
      }
      if (!this.checkboxGDRP) {
        alert("Du måste godkänna integritetspolicyn innan du kan fortsätta");
      } else {
        console.log("login", this.email, this.password);

        var register = await this.$auth
          .register(this.email, this.password)
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // [START_EXCLUDE]
            if (errorCode == "auth/weak-password") {
              alert("The password is too weak.");
            } else {
              alert(errorMessage);
            }
            console.log(error);
          });
        if (register) {
          console.log(register);
          await this.$auth.addUserRecord({
            firstname: this.firstname,
            lastname: this.lastname,
            city: this.city,
            contactPerson: this.contactPerson,
            company: this.company,
            phone: this.phone,
            email: this.email,
            customer: this.radioGroup,
          });

          this.$router.replace("buy");
        }
      }
    },
  },
};
</script>