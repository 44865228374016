<template>
  <div class="about">
    <v-row>
      <v-col>
        <h2
          style=""
          class="black--text mb-6 mt-4 display-1 text-xs-center font-weight-bold"
        >
          Välj ditt paket för att komma igång
        </h2>
      </v-col>
    </v-row>

    <v-row>
      
      <v-col cols="12" sm="12" md="6">
        <v-card class="mx-auto mt-2" max-width="400">
          <v-img
            class="white--text align-end"
            height="220px"
            src="@/assets/pay.jpg"
          >
            <v-card-title>Mindfullnesskurs</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0"> 3 990 kr inkl. moms </v-card-subtitle>

          <v-card-text class="text--primary">
            <div>
              Onlinekurs - Självledarskap med mindfulness och mental träning
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <stripe-checkout
              ref="checkoutRef2"
              mode="payment"
              :clientReferenceId="user.uid"
              :pk="publishableKey"
              :line-items="lineItems"
              :success-url="successURL"
              :cancel-url="cancelURL"
              @loading="(v) => (loading = v)"
            />
            <v-btn rounded @click="submit2" right color="orange" text>
              <v-icon left color="third"> mdi-cart </v-icon>
              Köp nu
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card class="mx-auto mt-2 justify-center" max-width="400">
          <v-img
            class="white--text align-end"
            height="220px"
            src="@/assets/pay.jpg"
          >
            <v-card-title>Mindfullnesskurs + Coaching </v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0"> 9 900 kr inkl. moms </v-card-subtitle>

          <v-card-text class="text--primary">
            <div>
              Onlinekurs - Självledarskap med mindfulness och mental träning + 3
              tillfällen Professionell Coaching
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <stripe-checkout
              ref="checkoutRef"
              mode="payment"
              :clientReferenceId="user.uid"
              :pk="publishableKey"
              :line-items="lineItems2"
              :success-url="successURL"
              :cancel-url="cancelURL"
              @loading="(v) => (loading = v)"
            />
            <v-btn rounded @click="submit" right color="orange" text>
              <v-icon left color="third"> mdi-cart </v-icon>
              Köp nu
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- <a class="pt-3" href="/menu">till menyn</a> -->
    <div></div>
  </div>
</template>


<script>
// @ is an alias to /src

import { mapState } from "vuex";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  name: "Buy",
  components: {
    StripeCheckout,
  },
  computed: {
    ...mapState(["user"]),
    displayName() {
      return !this.user ? this.user.email : "";
    },
  },
  data() {
    this.publishableKey =
      "pk_live_51IOTljBXEzcIgqlyFCj3uNSKX2vwr115F0zqqOD1itgfMd8hkghZysqnIjyzS6rMIc3T70z5jY56b49dFR6XMf5y006ToCnMFT";
    return {
      loading: false,
      lineItems: [
        {
          price: "price_1IWIdrBXEzcIgqlysYrahaeJ", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      lineItems2: [
        {
          price: "price_1Im07YBXEzcIgqlyLQfYXtUm", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: "https://education-73542.web.app/",
      cancelURL: "https://education-73542.web.app/",
    };
  },
  methods: {
    submit2() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef2.redirectToCheckout();
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>