<template>
  <v-container fluid fill-height>
    <v-layout justify-center column>
      <v-form v-model="valid" @submit.prevent="onSubmit">
        <v-container>
          <v-row>
            <v-col>
           
              <h1
                style="color: #000"
                class="mb-6 mt-4 display-3 text-xs-center font-weight-bold white--text text"
              >
                Meny
              </h1>
            </v-col>
          </v-row>
          <staggered-fade :duration="40" tag="div" class="row">
            <!-- Each element requires a data-index attribute in order for the transition to work properly -->
            <v-col
              cols="12"
              sm="6"
              lg="6"
              v-for="(item, index) in categories"
              :data-index="index"
              :key="index"
              class="menu-btn"
            > 
              <v-btn
                class=""
                v-ripple="{ class: `yellow` }"
                :disabled="item.locked"
                rounded
                block
                elevation="6"
                @click="navigateToQuestion(item)"
                large
              >
                <v-icon left v-if="isFinished(item.id)" color="third">
                  mdi-bookmark
                </v-icon>
                {{ item.name }}
              </v-btn>
            </v-col>
          </staggered-fade>
        </v-container>
      </v-form>
       <v-row>
            <v-col>
                 <h3
                style="color: #fff"
                class="mb-6 mt-6  text-xs-center"
              >Modulerna låses upp med en veckas mellanrum</h3>
            </v-col>
          </v-row>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src

import { db } from "../plugins/FirebaseAuthPlugin";
import StaggeredFade from "../components/StaggeredFade";
import { mapState } from "vuex";
import { addMinutes, isFuture, formatDistance} from 'date-fns'
export default {
  computed: {
    ...mapState(["user"]),
     overDue(item){
        if(Date.now() >  new Date(item.unlockAt)){ // your handler for overDue check.
            return 'color:red'
        }else return 'color:blue'
    }
  },
  methods: {
    addCategory() {
      this.categories.push({ id: 43243, name: "apa" });
    },
    persist() {
      localStorage.logo = this.logo;
      localStorage.background = this.menuBackground;
    },

    isFinished: function (id) {
      if (this.finishedCourses == null) {
        return false;
      }
      return this.finishedCourses.includes(id);
    },
    navigateToQuestion: function (item) {
      this.$router.push({ name: "questions", params: { id: item.id } });
    },
    getMenu() {
      if (localStorage.logo) {
        this.logo = localStorage.logo;
      }
      if (localStorage.background) {
        this.menuBackground = localStorage.background;
        this.$emit("updateBackground", this.menuBackground);
      } else {
        db.collection("settings")
          .doc("settings")
          .get()
          .then((doc) => {
            this.logo = doc.data().logo;
            this.menuBackground = doc.data().menuBackground;
            this.$emit("updateBackground", this.menuBackground);
            this.persist();
          });
      }

      this.$auth.getUserRecord().then((data) => {
        let userData = data.data();
        if (
          userData != null &&
          (userData.payed == null || userData.payed === false)
        ) {
          this.$router.push("buy");
        }

        db.collection("categories")
          .orderBy("sortorder")
          .get()
          .then((querySnapshot) => {
            let tmpArray = [];
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              var icon = data.icon != null ? data.icon : "none";
              tmpArray.push({ id: doc.id, name: data.name, icon: icon });
            });

            //Get bookmarks if user finished any courses
            this.$auth.getUserRecord().then((data) => {
              let userData = data.data();
              console.log("Userdata", userData);
              this.finishedCourses = userData.finishedCourses;
              let finishedCategory = userData.finishedCategory;
  
              if (this.finishedCourses) {
                
                if (this.finishedCourses.length == tmpArray.length) {
                  // this.diplomDialog = true;
                  this.$auth.updateUserCompleted();
                }
              }
              
              tmpArray.forEach(function (cat) {
                cat.locked = true;
                
                if (finishedCategory != null && finishedCategory[cat.id] != null) {
                  cat.finisedTime = finishedCategory[cat.id].time;
                }
              });
              tmpArray[0].locked = false;
              tmpArray[0].status = "Unlocked";
              for (var i = 0; tmpArray.length-1 > i; i++) {
                if (tmpArray[i].finisedTime != null) {
                  tmpArray[i].status = "Completed";
                   tmpArray[i].locked = false;
                  tmpArray[i + 1].status = "pending";
                  var unlockAt = addMinutes(new Date(tmpArray[i].finisedTime), 10080);
                  tmpArray[i + 1].status = "Will open in " + formatDistance(unlockAt, new Date());
                  
                  if(tmpArray[i + 1] != null && (!isFuture(unlockAt)))
                  {
                    tmpArray[i + 1].status = "Öppen";
                    tmpArray[i + 1].locked = false;
                  }
                } else {
                  tmpArray[i + 1].status = "locked";
                  tmpArray[i + 1].locked = true;
                }
              }
               this.categories = tmpArray;
            });
           
          })
          .then(() => {
            this.isVisible = !this.isVisible;
          });
      });
    },
  },
  mounted() {
    this.getMenu();
  },
  components: {
    StaggeredFade,
  },
  watch: {
    user() {
      console.log("WATCH USER");
      this.getMenu();
    },
  },
  data: () => ({
    nextNum: 10,
    menuBackground: "",
    logo: "",
    diplomDialog: false,
    finishedCourses: [],
    isVisible: false,
    categories: [],
    valid: false,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    items: ["Göteborg", "Uddevalla", "Strömstad", "Skövde"],
  }),
};
</script>

<style>
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #f5f5f5 !important;
}
.menu-btn .v-btn {
  height: 62px !important;
  border-radius: 31px;
}

.menu-btn .v-btn__content {
  flex: 1;
}

.home {
  font-family: "Trebuchet MS", sans-serif !important;
}

.swbuttonleft {
  width: 380px;
  background-color: #7f7f7f !important;
  height: 76px;
  text-align: left;
  margin: 10px;
  border-radius: 50px !important;
}
.swbuttonright {
  width: 380px;
  background-color: #7f7f7f !important;
  height: 76px;
  text-align: left;
  margin: 10px;
  border-radius: 50px !important;
}

.swbuttonleft .v-btn__content {
  justify-content: start;
}

.swbuttonright .v-btn__content {
  justify-content: end;
}

.swbuttonright .v-btn__content {
  margin-left: 25px;
}

.v-btn .v-icon--left {
  margin-right: 16px;
  margin-left: 23px;
}

.v-btn .v-icon--right {
  margin-right: 30px;
  margin-left: 23px;
}

body {
}

.sidebar {
  padding: 20px;
}

.item {
  width: 300px;
  height: 50px;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 10px;
}

.v-btn__content {
  white-space: normal;
  width: inherit;
  word-break: break-word;
}

.staggered-transition {
  transition: all 0.5s ease;
  opacity: 1;
}
.staggered-enter,
.staggered-leave {
  opacity: 0;
}
</style>


